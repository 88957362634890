import React from "react";

// Customizable Area Start
import { Container, Box, Typography,  Dialog,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  Tooltip
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {CustomPagination} from './RequestManagement.web'
import moment from "moment";
import { leftIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import SentRequestController, {
  Props,
  configJSON,
} from "./SentRequestController";

export default class SentRequest extends SentRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleStatusColor = (status:string) => {    
    switch(status){
      case "pending" : 
        return {
          borderRadius : '20px',
          padding : '2px 10px',
          letterSpacing: "0em",
          textAlign : 'center',
          fontFamily: "Inter",
          fontSize: "12px",
          backgroundColor : "#fce2e1" ,
          fontWeight: 500,
          lineHeight: "16px",
          marginTop: "10px",
          textTransform: "capitalize",
          color : "#F29339",
        }
        case "rejected" : 
        return {
          lineHeight: "16px",
          backgroundColor : "#fce2e1" ,
          borderRadius : '20px',
          padding : '2px 10px',
          textAlign : 'center',
          fontFamily: "Inter",
          textTransform: "capitalize",
          fontSize: "12px",
          fontWeight: 500,
          marginTop: "10px",
          letterSpacing: "0em",
          color : "#b85657",
        }
        case "accepted" : 
        return {
          textTransform: "capitalize",
          color : "#277159",
          borderRadius : '20px',
          padding : '2px 10px',
          textAlign : 'center',
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 500,
          backgroundColor : "#d1fae5" ,
          lineHeight: "16px",
          marginTop: "10px",
          letterSpacing: "0em",
        }
       }
  }

  renderTableRows = () => {    
    if(this.props.logsData?.paginated_data?.length > 0){
    return this.props.logsData.paginated_data
      .map((row:any) => (
        <TableRow key={row.id} style={tableStyles.tableRow}>
        {/* @ts-ignore */}
        <TableCell style={tableStyles.tableBodyText}>{moment(row.start_date).format("Do MMMM, YYYY")}</TableCell>
        {/* @ts-ignore */}
        <TableCell style={tableStyles.tableBodyText}>{moment(row.end_date).format("Do MMMM, YYYY")}</TableCell>
        <TableCell style={tableStyles.requestCell}><Box>
          {/* @ts-ignore */}
<Typography style={tableStyles.tableBodyText}>Request for {row?.action_type == "wfh" ? "WFH" : "Leave"}</Typography>
        <Tooltip title={row?.reason}>
        <Typography className="ellipsis_text" style={tableStyles.dotText}>{row?.reason}</Typography>
</Tooltip>
        </Box></TableCell>
        {/* @ts-ignore */}
        <TableCell><Typography style={this.handleStatusColor(row.status)}>{row.status=="accepted"?"Approved":row.status}</Typography></TableCell>
        <TableCell>
          {/* @ts-ignore */}
          {row.status == "rejected" && <Typography style={tableStyles.textView} onClick={this.showReasonView.bind(this,row?.feedback)}>View</Typography>}
        </TableCell>
      </TableRow>
      ));
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const totalCount = parseInt(this.props.logsData?.total_count);
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" >
          {/* @ts-ignore */}
        <Dialog open={this.props.openLogs} onClose={this.props.closeLeaveWFHLogs()} fullWidth maxWidth="md" style={tableStyles.myDialog}>
        {this.state.showReasonView ? 
        <Grid container style={{padding:"20px",}}>
        <Grid item style={{marginBottom  : '15px'}}>
            <Typography style={tableStyles.tableHeading}>Reason For Rejection</Typography>            
            {/* @ts-ignore */}
            <CloseIcon onClick={this.hideReasonView} style={modalStyles.closeIcon}  />           
          </Grid>
          {/* @ts-ignore */}
          <Paper style={tableStyles.feedbackTablePaper}>
            {/* @ts-ignore */}
            <Typography style={webStyle.reasonLabel}>{this.state.feedback}</Typography>
         
            </Paper>

            <Grid container style={{marginTop: '20px'}}>

        <Box onClick={this.hideReasonView}>
          <Typography 
           style={webStyle.leftArrowText}
           >
            <img src={leftIcon} 
            style={webStyle.leftArrowText}
            /> Back</Typography>
      </Box>
           </Grid>
           </Grid>
           : 
           <Grid container style={{padding:"20px",}}>
           <Grid item style={{marginBottom  : '15px'}}>
            <Typography style={tableStyles.tableHeading}>Leaves/WFH Logs</Typography>            
            {/* @ts-ignore */}
            <CloseIcon onClick={this.props.closeLeaveWFHLogs()} style={modalStyles.closeIcon}  />           
          </Grid>
            {/* @ts-ignore */}
          <Paper style={this.props.logsData?.total_count == 0 ? tableStyles.tablePaperNoData : tableStyles.tablePaper}>
            {this.props.logsData?.total_count == 0 ? 
            // @ts-ignore
            <Typography style={tableStyles.labelNoRequest}>You have not applied for any leaves/WFH yet!</Typography> : 
            // @ts-ignore
            <Table style={tableStyles.tableContainer}>              
              <TableHead style={tableStyles.tableHead}>
                <TableRow style={tableStyles.tableRow}>
                <TableCell style={webStyle.label}>FROM DATE</TableCell>
                <TableCell style={webStyle.label}>TO DATE</TableCell>
                  <TableCell style={webStyle.label}>REASON FOR LEAVE/WFH</TableCell>
                  <TableCell style={webStyle.label}>STATUS</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {/* @ts-ignore */}
              <TableBody style={tableStyles.tableBody}>{this.renderTableRows()}</TableBody>
            </Table>}
            </Paper>

            {totalCount != 0 && <Grid container style={{marginTop: '20px'}}>
              <CustomPagination currentPage={this.props.page} totalPages={
                totalCount < 4 ? 1 :Math.ceil(totalCount/4)} onChange={(value: any)=>{
                this.props.handleGetLeaveLogs(value)
                }}/>
           </Grid>}
           </Grid>}
        </Dialog>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const tableStyles = {
  tableHeading: {
   fontFamily: "Inter",
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: "28px",
    letterSpacing: "0em",
    color: "#335693",
  },
  feedbackTablePaper:{
    width: '100%',
    overflowX: 'auto', 
    height:"266px",
    padding:"10px"
  },
  tablePaperNoData:{
    width: '100%',
    overflowX: 'auto', 
    minHeight:"266px",
    justifyContent:'center',
    alignItems:"center",
    display:"flex"
  },
  tablePaper:{
    width: '100%',
    overflowX: 'auto', 
    minHeight:"266px",
  },
  tableContainer:{
    "&.MuiTableRoot":{
      borderRadius :'6px',
      minWidth : '300px' 
    },
    
  },
  tableHead: {
    background: "#f9fafb",
    borderRadius: "8px",
    "& .MuiTableCellHead": {
      padding: "10px 16px",
      fontSize: "16px",
    },
  },
  tableBody:{
    "& .MuiTableCellBody":{
    // fontSize : '16px',
    color :'#1C1C1C '

    }
  },
  myDialog:{
    "& .MuiDialogPaper":{
      padding : '20px 15px',
      position : 'relative',
    }
  },

  tableBodyText: {
    letterSpacing: "0em",
    fontFamily: "Inter",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "left",
  },
  tableRow: {
    
  },
  aboutView: {
    display: "flex",
    flexDirection: "row",
  },
  avatarImage: {
    objectFit: "cover",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  name: {
    fontSize: "14px",
    color: "#111827",
    fontWeight: 500,
    fontFamily: "Inter",
    lineHeight: "20px",
  },
  email: {
    color: "#6B7280",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  requestCell: {
    maxWidth: "0px",
  },
  dotText: {
    maxWidth: "100%",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#64748B",
  },
  labelNoRequest: {
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  buttonsView: {
    justifyContent: "end",
    flexDirection: "row",
    display: "flex",
  },
  textView: {
    color: "#0B357D",
    fontFamily: "Inter",
    fontSize: "14px",
    letterSpacing: "0em",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    cursor: "pointer",
  },
  textViewReject: {
    letterSpacing: "0em",
    fontFamily: "Inter",
    fontSize: "14px",
    marginLeft: "15px",
    fontWeight: 400,
    textAlign: "left",
    lineHeight: "20px",
    cursor: "pointer",
    color: "#F87171",
  },
};

const modalStyles = {
  closeIcon: {
    top: "20px",
    position: "absolute",
    right: "15px",
    color: "#94A3B8",
    cursor: "pointer",
  },
};

const webStyle = {
  leftArrowText:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    color: '#0B357D',
    display:'flex', alignItems:'center',
    paddingRight:"10px",
    cursor:"pointer"
  },
  reasonLabel:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#475569',
  },
  label: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "26px",
    marginTop: "10px",
  },
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },
};
// Customizable Area End
