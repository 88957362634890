import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { isEmail } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name:string;
  email:string;
  message:string;
  emailErrorMessage:string;
  isNameError:boolean;
  isEmailError:boolean;
  isMessageError:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendMessageApiCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      name:"",
      email:"",
      message:"",
      emailErrorMessage:"",
      isNameError:false,
      isEmailError:false,
      isMessageError:false,
    };

    this.sendMessageApiCallId="";
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId ==this.sendMessageApiCallId){
        this.handleResponse(responseJson, errorResponse)
     }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goBack=()=>{
    this.props.history.goBack();
  }

  handleResponse=(responseJson:any, errorResponse:any)=>{
    if(responseJson?.meta?.message){
      toast.success(responseJson?.meta?.message)
      setTimeout(this.goBack, 2000);
    }else if(errorResponse?.meta?.message){
      toast.error(errorResponse?.meta?.message)
    }
  }
  changeName=(e: { target: { value: any; }; })=>{
    this.setState({name:e.target.value, isNameError: false})
  }
  changeEmail=(e: { target: { value: any; }; })=>{
    this.setState({email:e.target.value, isEmailError: false, emailErrorMessage:""})
  }
  changeMessage=(e: { target: { value: any; }; })=>{
    this.setState({message:e.target.value, isMessageError: false})
  }
  
  sendMessage=()=>{
      let isError = false;
  
      if(this.state.name.trim() == ""){
        this.setState({isNameError:true})
        isError = true;
      }
      const isValidEmail = isEmail("email",this.state.email.trim());
      
      if(this.state.email.trim() == "" || !isValidEmail.status){
        if(isValidEmail.message == "Invalid email address."){
          this.setState({isEmailError:true, emailErrorMessage: "Please enter a valid email."})
          isError = true;
        }else{
          this.setState({isEmailError:true, emailErrorMessage: "Please enter your email."})
          isError = true;
        }
      }
      if(this.state.message.trim() == ""){
        this.setState({isMessageError:true})
        isError = true;
      }     
    
      if(isError){
        return;
      }
  
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        "data":{
            "attributes":{
                "name":this.state.name,
                "email":this.state.email,
                "message":this.state.message}
        }
    }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.sendMessageApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_privacy_setting/contact_us_forms"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
