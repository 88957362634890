import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Hidden,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckBox from '@material-ui/icons/CheckBoxOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.rootContainer}>
          <Hidden mdDown>
        <Box style={webStyle.container}>
      <div style={webStyle.backArrow} >
        <ArrowBackIcon style={webStyle.iconStyle} onClick={this.goBack}/>
        <Typography style={webStyle.backArrowText as React.CSSProperties} onClick={this.goBack}>
          Back
        </Typography>
      </div>
      <div style={webStyle.notificationView}>
        <Typography style={webStyle.mainText as React.CSSProperties}>
        {configJSON.notifications}
        </Typography>
        {this.state.data?.length != undefined && this.state.data?.length != 0 && <div style={webStyle.backArrow}>
        <CheckBox style={webStyle.iconStyle} onClick={this.markAllRead}/>
        <Typography style={webStyle.markAllReadText as React.CSSProperties} onClick={this.markAllRead}>
        Mark all read
        </Typography>
        <DeleteOutlineOutlined style={webStyle.iconStyle} onClick={this.deleteAllNotifications}/>
        <Typography style={webStyle.markAllClearText as React.CSSProperties} onClick={this.deleteAllNotifications}>
        Clear all
        </Typography>
        </div>}
      </div>
        </Box>
        </Hidden>
        <Box style={webStyle.notificationContainer as React.CSSProperties}>
        {this.state.data?.length == undefined || this.state.data?.length == 0 ? (
          <Typography style={webStyle.paperNoRequest}>
            No new notifications at this time.
          </Typography>
        ) : (
          this.state.data
            ?.map((item: any) => {
              return (
                <Box
                  key={item.attributes.id}
                  style={webStyle.itemWrapper}
                >
                  <Box style={{ flex: 1 }}>
                    <Box style={webStyle.itemHeadingWrapper}>
                      <Typography
                        variant="h6"
                        style={
                          item.attributes.is_read
                            ? webStyle.itemHeadingRead
                            : webStyle.itemHeading as React.CSSProperties
                        }
                      >
                        {item.attributes.headings}
                      </Typography>
                        
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e=>this.handleClick(e,item)}>
                        <MoreHoriz/>
      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                      >
                        <MenuItem onClick={this.markAsRead}>Mark as read</MenuItem>
                        <MenuItem onClick={this.deleteNotifications}>Remove this notification</MenuItem>
                      </Menu>
                    </Box>
                    <Typography variant="h6" style={webStyle.timeText}>
                      {this.timeSince(item.attributes.created_at)} {" ago"}
                    </Typography>
                  </Box>
                </Box>
              );
            })
        )}
        </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    background: "#fff",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    display: "flex",
  },
  rootContainer:{
    marginLeft:"50px",
  },
  container: {
    borderRadius:8,
    padding: "20px",
    border: '1px solid #E2E8F0',
    backgroundColor:'#fff',
  },
  paperNoRequest: {
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  notificationContainer: {
    borderRadius:8,
    padding: "20px",
    border: '1px solid #E2E8F0',
    backgroundColor:'#fff',
    marginTop:20,
    overflowY:'scroll'
  },
  notificationView:{
    alignItems: 'center',
    display: 'flex',
    justifyContent: "space-between"
  },
  backArrow: {
    marginBottom: "1px",
    alignItems: 'center',
    display: 'flex',
  },
  backArrowText: {
    cursor: 'pointer',
    marginLeft:"10px",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0em',
    color:"#0B357D",
    fontFamily: 'Inter',
  },
  markAllReadText:{
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: 14,
    letterSpacing: '0em',
    lineHeight: '22px',
    marginLeft:"5px",
    marginRight:"10px",
    color:"#0B357D",
    cursor: 'pointer',
  },
  markAllClearText:{
    cursor: 'pointer',
    marginLeft:"5px",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    color:"#0B357D",
    fontFamily: 'Inter',
  },
  mainText: {
    letterSpacing: '-0.005em',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32px',
    color:"#334155",
    fontFamily: 'Inter',
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginBottom: 10,    
    cursor: "pointer",
    borderBottom: "1px solid #E2E8F0",
    paddingBottom: '10px'
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
    justifyContent: 'space-between'
  },
  iconStyle: {color:"#0B357D", cursor: 'pointer',},
  itemHeading: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    color: '#475569',
  },
  itemHeadingRead: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: "#475569",
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  timeText:{
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#94A3B8',
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
