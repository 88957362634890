export const menuIcon = require("../assets/menu.svg")
export const assignmentIcon = require("../assets/assignment_turned_in.svg")
export const calenderIcon = require("../assets/calendar_month.svg")
export const dashboardIcon = require("../assets/dashboard.svg")
export const forumIcon = require("../assets/forum.svg")
export const NotificationIcon = require("../assets/notifications.svg")
export const profileImage = require("../assets/profileimage.png")
export const appLogo = require("../assets/tielogo.png")
export const dropdown = require("../assets/dropdown.svg")

export const Editicon =require("../assets/editicon.svg")
export const AddIcon =require("../assets/add.svg")
export const profile=require("../assets/ThreeDeeprofile.png")
export const Editdark=require("../assets/editdark.svg")
export const leftIconGrey=require("../assets/lefticongrey.png")
export const rightIconGrey=require("../assets/righticongrey.png")
export const leftIcon=require("../assets/lefticon.png")