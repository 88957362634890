import React from "react";

// Customizable Area Start
import {
  Grid,
  Paper,
  Typography,
  Button,
  OutlinedInput,
  FormHelperText,
  Box,
  Hidden,
} from "@material-ui/core";
import { backarrow, contactuslogo, tielogo } from "./assets";
// Customizable Area End

import LandingPageController, {
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  // Customizable Area Start
  getErrorView=(showError:boolean, value:string, webStyle:any)=>{
    if(showError){
      return <FormHelperText style={webStyle.errorText} id={value}>{value}</FormHelperText>
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start     
       
    <div style={webStyle.container}>
    <Grid container justifyContent="center" alignItems="center">
      <img src={backarrow} style={webStyle.arrowStyle as React.CSSProperties} onClick={this.goBack}/>
      <Hidden mdDown>
      {/* @ts-ignore */}
      <Grid item xs={12} lg={6} style={webStyle.childGrid}>
        <Box
          style={webStyle.box}
        >
          <img
            src={contactuslogo}
            style={webStyle.sideLogo}
          />
        </Box>
      </Grid>
      </Hidden>
      <Grid item xs={12} lg={6} style={webStyle.childGrid1} justifyContent="center" alignItems="center">
        <Box
          style={webStyle.box1}
        >
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              //@ts-ignore
              style={webStyle.paper}
            >
              <img
                src={tielogo}
                alt="Centered Image"
                style={webStyle.img}
              />
              <Grid item style={{ textAlign: "start" }}>
                <Typography
                // @ts-ignore
                  style={webStyle.title}
                >
                  Contact us
                </Typography>
                <Typography variant="body1" style={webStyle.desc}>
                  Reach out and we will get in touch within 24 hours
                </Typography>
                <Typography style={webStyle.label}>Name</Typography>
                <OutlinedInput
                  error={this.state.isNameError}
                  fullWidth
                  placeholder="Name here"
                  style={webStyle.input}
                  value={this.state.name}
                  onChange={this.changeName}
                />
                {this.getErrorView(this.state.isNameError, "Please enter your name.", webStyle)}
                <Typography style={webStyle.label}>Email Address</Typography>
                <OutlinedInput
                  error={this.state.isEmailError}
                  fullWidth
                  style={webStyle.input}
                  placeholder="Email address here"
                  value={this.state.email}
                  onChange={this.changeEmail}
                />
                {this.getErrorView(this.state.isEmailError, this.state.emailErrorMessage, webStyle)}
                <Typography style={webStyle.label}>Message</Typography>
                <OutlinedInput
                  error={this.state.isMessageError}
                  fullWidth
                  style={webStyle.input}
                  multiline
                  rows={4}
                  placeholder="Message here"
                  value={this.state.message}
                  onChange={this.changeMessage}
                />
                {this.getErrorView(this.state.isMessageError, "Please enter your message.", webStyle)}
                {/* @ts-ignore */}
                <Button variant="contained" style={webStyle.btnStyle} data-testid="sendmsgbtn" onClick={this.sendMessage}>
                {/* @ts-ignore */}
                  <Typography style={webStyle.btnLabel}>
                    Send message
                  </Typography>
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  childGrid: {
    height: "100vh",
    width: "100vw",
    borderRadius: "0 0 100px 0",
    backgroundColor: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  childGrid1: {
    width: "100vw",
  },
  container: {
    background:
      "linear-gradient(to bottom, rgb(255, 255, 255), rgba(7, 120, 238, 0.19931722689075626))",
  },
  arrowStyle : {
    position: 'absolute',
    top: '50px',
    left: '50px',
    cursor: 'pointer',
    height: '35px',
  },
  errorText:{
    color:'red'
  },
  label: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    marginTop: "10px",
  },
  btnStyle: {
    marginTop: "20px",
    backgroundColor: "#0B357D",
    width: "100%",
    borderRadius: "8px",
  },
  btnLabel: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    // @ts-ignore
    color: "white",
    padding: "10px",
    textTransform: "initial",
  },
  input:{
    borderRadius: "8px",
    fontFamily: "Inter",
  },
  title:{
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "24px",
    marginTop: "10px",
    color:"#0B357D"
  },
  desc:{ marginBottom: "5px", 
  fontFamily: "Inter",
  fontSize:"14px"
},
img:{ width: "70%", height: "70%" },
paper:{
  padding: "20px",
  textAlign: "center",
  borderRadius: "16px",
},
sideLogo:{ height: 512, width: 512 },
box:{
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  alignItems: "center",
},
box1:{
  display: "flex",
  justifyContent: "center",
  // height: "100vh",
  alignItems: "center",
},
};
// Customizable Area End
