// Customizable Area Start
import React from 'react';
import LayoutControllerWeb from './LayoutController.web';
import { withRouter } from 'react-router-dom';
import { Drawer, Menu, MenuItem, Typography } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import {
  appLogo, menuIcon, profileImage,
  assignmentIcon, assignmentturnIn, calenderIcon,
  dashboardIcon, dashboardturnin, forumIcon, forumturnIn
} from '../../dashboard/src/assets';
import { modal } from './assets'

const drawerWidth = "100px";
// Customizable Area End

// Customizable Area Start
class Layout extends LayoutControllerWeb {

  menuArr = [
    { id: 1, menuItem: "View Profile", to: "/viewprofile" },
    { id: 2, menuItem: "Contact us", to: "/contactus" },
    { id: 3, menuItem: "Privacy Policy", to: "/privacypolicy" },
    { id: 4, menuItem: "Terms and Conditions", to: "/termsconditions" },
    { id: 5, menuItem: "Log out", to: "/signin" },
  ];

  array = [
    {
      id: 1,
      text: "Dashboard",
      icon: dashboardIcon,
      to: "/",
      turnedInIcon: dashboardturnin,
    },
    {
      id: 2,
      text: "Attendance",
      icon: assignmentturnIn,
      to: "/attendance",
      turnedInIcon: assignmentIcon,
    },
    {
      id: 3,
      text: "Meetings",
      icon: calenderIcon,
      to: "/",
      turnedInIcon: forumturnIn,
    },
    {
      id: 4,
      text: "Community",
      icon: forumIcon,
      to: "/",
      turnedInIcon: assignmentturnIn,
    },
  ];

  renderMenuItems = (items: any[]) => {
    const { activeLink } = this.state;
    return items.map((item) => (
      <IconAndText key={item.id}>
        <CustomIcon
          data-testid="redirectButton"
          src={activeLink === item.to ? item.turnedInIcon : item.icon}
          alt=""
          onClick={() => this.handleIconClick(item.to)}
        />
        <CustomText>{item.text}</CustomText>
      </IconAndText>
    ));
  };

  renderDrawerContent = (drawerTitle: string, items: any[]) => {
    return (
      <SecondDrawer variant="permanent" open>
        <DrawerInsideDiv>
          <LogoImageBox>
            <LogoImage src={appLogo} />
          </LogoImageBox>
          {this.renderMenuItems(items)}
          <MenuDiv>
            <ImageBox onClick={this.handleMenuClick}>
              <ProfileImage src={modal} />
            </ImageBox>
            <CustomMenu
              id="simple-menu"
              anchorEl={this.state.menuOpen}
              keepMounted
              open={Boolean(this.state.menuOpen)}
              data-testid="clickOutsideButtonID"
              onClose={this.handleMenuClose}
            >
              {this.menuArr.map((item) => (
                <CustomMenuItem
                  key={item.id}
                  onClick={this.handleMenuClose}
                  data-testid="clickOutsideButtonID"
                >
                  <p onClick={() => this.menuClcik(item.to)}>{item.menuItem}</p>
                </CustomMenuItem>
              ))}
            </CustomMenu>
          </MenuDiv>
        </DrawerInsideDiv>
      </SecondDrawer>
    );
  };

  render() {

    let container = window !== undefined ? () => window.document.body : undefined;
    const { mobileOpen } = this.state;
    return (

      <MainBox>
        <SideBarMain>
          <Maindiv>
            <HamburgerLogo src={menuIcon} onClick={this.handleDrawerToggle}  data-testid="hamburgerButtonId"/>
            <NavSub>
              <MainDrawer>
                <FirstDrawer
                  container={container}
                  variant={window && window.innerWidth >= 1200 ? "permanent" : "temporary"}
                  open={Boolean(mobileOpen)}
                  onClose={this.handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <DrawerInsideDiv>
                    <LogoImageBox>
                      <LogoImage src={appLogo} width="100px" height="10px" />
                    </LogoImageBox>
                    {this.renderMenuItems(this.array)}
                    <MenuDiv>
                      <ImageBox onClick={this.handleMenuClick} data-testid="profilePictureButton">
                        <ProfileImage src={profileImage} />
                      </ImageBox>
                      <CustomMenu
                        id="simple-menu"
                        anchorEl={this.state.menuOpen}
                        keepMounted
                        open={Boolean(this.state.menuOpen)}
                        onClose={this.handleMenuClose}
                        data-testid="clickOutsideButtonID"
                      >
                        {this.menuArr.map((item) => (
                          <CustomMenuItem
                            key={item.id}
                            onClick={this.handleMenuClose}
                            data-testid="clickOutsideButtonID"
                          >
                            <p onClick={() => this.menuClcik(item.to)} data-testid="popUpItemButton">
                              {item.menuItem}
                            </p>
                          </CustomMenuItem>
                        ))}
                      </CustomMenu>
                    </MenuDiv>
                  </DrawerInsideDiv>
                </FirstDrawer>

                {this.renderDrawerContent("Second Drawer", this.array)}
              </MainDrawer>
            </NavSub>
          </Maindiv>
        </SideBarMain>
        <AttendanceMain>
          {this.props.children}
        </AttendanceMain>
      </MainBox>
    )
  }
}
// Customizable Area End

// Customizable Area Start

const MainBox = styled("div")({
  backgroundColor: "#F5F9FF",
  padding: "0px 56px 10px 24px",
  display: "flex",
  height: "100vh",
  overflow: "scroll",

  "@media (max-width: 900px)": {
    width: "100%",
    padding: "20px",
  },
  "@media (max-width: 700px)": {
    padding: "10px",
  },
});

const SideBarMain = styled("div")({
  width: "10%",
  "@media (max-width: 900px)": {
    width: "0px",
  },
});
const Maindiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "8px",
  position: "relative",
  zIndex: 1111,
});

const NavSub = styled("div")({
  minHeight: "74px",
  "@media (max-width: 900px)": {
    width: "0px",
  },
});

const MainDrawer = styled("div")({
  "@media (min-width: 1200px)": {
    width: drawerWidth,
    flexShrink: 0,
  },

  "@media (min-width: 600px)": {
    width: "100%",
    marginLeft: 0,
  },

  "@media (max-width: 900px)": {
    display: "none",
  },
});

const FirstDrawer = styled(Drawer)({
  display: "flex",
  flexDirection: "column",
  "& .MuiDrawer-paper": {
    width: "10%",
    backgroundColor: "#0B357D",
    border: "none",
    borderRight: "1px solid #80808042",
    display: "flex",
    justifyContent: "space-between",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    "@media (max-width: 900px)": {
      width: "250px",
    },
  },
  "& .MuiDrawer-paper::-webkit-scrollbar": {
    display: "none",
  },
});

const SecondDrawer = styled(Drawer)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "& .MuiDrawer-paper": {
    width: "10%",
    backgroundColor: "#0B357D",
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  "& .MuiDrawer-paper::-webkit-scrollbar": {
    display: "none",
  },
});

const DrawerInsideDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100%",
});

const LogoImageBox = styled("div")({
  marginTop: 40,
});

const LogoImage = styled("img")({
  height: "50px",
  width: "100%",
  objectFit: "cover",
  backgroundColor: "white",
});

const MiddleBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  gap: "24px",
});

const MenuDiv = styled("div")({});

const CustomMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    display: "flex",
    width: "180px",
    padding: "4px",
    flexDirection: "column",
    alignItems: "center",
    gap: "2px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
});

const CustomMenuItem = styled(MenuItem)({
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  padding: "9px 20px 9px 16px",
});

const ImageBox = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "32px",
  cursor: "pointer",
});

const ProfileImage = styled("img")({
  height: "40px",
  width: "40px",
  borderRadius: "50%",
  objectFit: "cover",
  border: "2px solid white",
});

const IconAndText = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "3px",
  paddingTop: "9px",
  cursor: "pointer",
});

const CustomIcon = styled("img")({
  width: "24px",
  height: "24px",
});

const CustomText = styled(Typography)({
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "18px",
});

const HamburgerLogo = styled("img")({
  height: "30px",
  marginBottom: "5px",
  cursor: "pointer",
  "@media (min-width: 900px)": {
    display: "none",
  },
  "@media (max-width: 700px)": {
    marginLeft: "30px",
    marginTop: "-40px",
  },
});
const AttendanceMain = styled("div")({
  width: "100%"
});
export default withRouter(Layout);
export { Layout }
// Customizable Area End