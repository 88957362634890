export const notification = require("../assets/notification.png");
export const feedback = require("../assets/feedback.png");
export const leaves = require("../assets/leaves.png");
export const calender = require("../assets/calender.png");

export const menuIcon = require("../assets/menu.svg");
export const assignmentIcon = require("../assets/assignment_turned_in.svg");
export const calenderIcon = require("../assets/calendar_month.svg");
export const dashboardIcon = require("../assets/dashboard.svg");
export const forumIcon = require("../assets/forum.svg");
export const notificationIcon = require("../assets/notifications.svg");
export const profileImage = require("../assets/profileimage.png");
export const appLogo = require("../assets/tielogo.png");
export const dropdown = require("../assets/dropdown.svg");

export const forumturnIn = require("../assets/forumturnIn.svg");
export const assignmentturnIn = require("../assets/assignmentturnIn.svg");
export const calendarturnIn = require("../assets/calendarturnIn.svg");
export const dashboardturnin = require("../assets/dashboardturnin.svg");
