// Customizable Area Start
import React from 'react'
import Dashboardcontrollerweb from "./DashboardController.web";
import { withRouter } from 'react-router-dom';
import { ArrowDropDown, CloseRounded, DehazeRounded } from '@material-ui/icons';
import { feedback, leaves, notification, calender } from './assets';
import { Modal, Box, TextField, InputAdornment, OutlinedInput, FormHelperText, } from '@material-ui/core';
import moment from 'moment';
import LeaveWFHLogs from '../../requestmanagement/src/SentRequest.web';
import RequestManagementWeb from '../../requestmanagement/src/RequestManagement.web';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

// Customizable Area End

// Customizable Area Start
class Dashboard extends Dashboardcontrollerweb {

    errorView = (showError: boolean, value: string, webStyle: any) => {
        if (showError) {
            return <FormHelperText style={webStyle.errorText} id={value}>{value}</FormHelperText>
        }
    }

    dashboardHeader = () => {
        return (
            <Box className="dashboard_label_menu_div dashboard_inner_div">
                <span><DehazeRounded /></span>
                <span className="dashboard_title_span">Dashboard</span>
                <Box className="dashboard_menu_div">
                    <Box onClick={this.openLeaveWFHLogs}>
                        <img src={leaves} alt="attendance image" />
                        <span>Leaves/WFH Approvals</span>
                    </Box>
                    <Box
                        data-testid="feedbackButtonID"
                        onClick={this.handleFeedbackmodal}
                    >
                        <img src={feedback} alt="attendance image" />
                        <span
                        >Feedback/Complaint</span>
                    </Box>
                    <Box onClick={this.handleNotification}>
                        <img src={notification} alt="attendance image" />
                        <span>Notification</span>
                    </Box>
                </Box>
            </Box>
        )
    }

    employeDetails = () => {
        return (
            <Box className="dashboard_inner_div">
                <Box className="employee_title_name_wrapper">
                    <Box className="dashboard_sub_heading">Employee Detail:</Box>
                    <Box>
                        <span className='dashboard_sub_heading'> {`${this.state.dashboardApiData?.first_name} ${this.state.dashboardApiData?.last_name}`} </span><span>(
                            {
                                this.capitalizeFirstWord(this.state.dashboardApiData?.employment_type)
                            })</span>
                    </Box>
                </Box>
                <Box className="employee_detail_div">
                    <Box>
                        <span>DATE OF JOINING</span>
                        <span>{this.state.dashboardApiData?.date_of_joining && moment(this.state.dashboardApiData?.date_of_joining).format("DD/MM/YYYY")}</span>
                    </Box>
                    <Box>
                        <span>DATE OF CONFIRMATION</span>
                        <span>{this.state.dashboardApiData?.date_of_confirmation && moment(this.state.dashboardApiData?.date_of_confirmation).format("DD/MM/YYYY")}</span>
                    </Box>
                    <Box>
                        <span>EMPLOYEE DESIGNATION</span>
                        <span>{this.state.dashboardApiData?.designation}</span>
                    </Box>
                    <Box>
                        <span>MANAGER</span>
                        <span>{this.state.dashboardApiData?.manager?.first_name} {this.state.dashboardApiData?.manager?.last_name}</span>
                    </Box>
                </Box>
                <Box className="employee_detail_button_div">
                    <button
                        data-testid="wfhOpenButtonID"
                        onClick={this.handleWfhModal}>
                        Apply for WFH
                    </button>
                    <button
                        data-testid="leaveModalOpenButtonID"
                        onClick={this.handleapplyLeaveModal}>
                        Apply for Leave
                    </button>
                </Box>
            </Box>
        )
    }

    leavesDetails = () => {
        const { number_of_leaves, leaves_left, wfh_left, number_of_wfh } = this.state.dashboardApiData || {};
        const numberOfLeavesAccured = number_of_leaves - leaves_left;
        const numberOfWFHAccured = number_of_wfh - wfh_left;
        const leavesAccured = isNaN(numberOfLeavesAccured) ? 0 : numberOfLeavesAccured;
        const wfhAccured = isNaN(numberOfWFHAccured) ? 0 : numberOfWFHAccured;
        const accuredLeaves = `${leavesAccured}/${wfhAccured}`;

        return (
            <Box className="dashboard_inner_div">
                <Box className="employee_title_name_wrapper">
                    <Box className="dashboard_sub_heading">Leaves Details</Box>
                    <Box className="date_selector_div">
                        Jun 2022 to Jun 2023 <ArrowDropDown />
                    </Box>
                </Box>
                <Box className="employee_detail_div employee_leave_detail_div">
                    <Box>
                        <span>YEARLY LEAVES/WFH</span>
                        <span>{this.state.dashboardApiData?.number_of_leaves}{this.state.dashboardApiData?.number_of_leaves && "/"}{this.state.dashboardApiData?.number_of_wfh}</span>
                    </Box>
                    <Box>
                        <span>ACCRUED LEAVES/WFH</span>
                        <span>{accuredLeaves}</span>
                    </Box>
                    <Box>
                        <span>REMAINING LEAVES</span>
                        <span>{this.state.dashboardApiData?.leaves_left || 0}</span>
                    </Box>
                    <Box>
                        <span>REMAINING WFH</span>
                        <span>{this.state.dashboardApiData?.wfh_left || 0}</span>
                    </Box>
                </Box>
            </Box>
        )
    }

    timeSheet = () => {
        return (
            <Box className="dashboard_inner_div">
                <Box className="employee_title_name_wrapper">
                    <Box className="dashboard_sub_heading">Time Sheet</Box>
                    <Box className="date_selector_div"  data-testid="opencalendar" onClick={this.handleTimeSheetDateCalendar}>
                       {`${moment(this.state.ranageStartDate ? this.state.ranageStartDate :  moment().startOf('month')).format('Do MMM YYYY')} to ${ moment(this.state.rangeEndDate ? this.state.rangeEndDate : new Date()).format('Do MMM YYYY')}`} <ArrowDropDown />
                    </Box>
                </Box>
                   <DatePicker
                        selectsRange={true}
                        open={this.state.oepnCalendar}
                        startDate={this.state.ranageStartDate}
                        endDate={this.state.rangeEndDate}
                        onChange={(update) => this.handleSetValue(update)}
                        withPortal
                        onClickOutside={this.handleCloseCalendar}
                        ref={this.timeSheetCalendar}
                        showPopperArrow={false}
                        customInput={
                          <div style={{ display: 'none' }} />
                        }
                        maxDate={new Date()}
                        data-testid="datepickertimesheet"
                        />
                <Box className="time_sheet_div">
                    <Box className="time_sheet_inner_div">
                        <span className='time_sheet_title'>Time Sheet</span>
                        <Box className='line_div' />
                        <Box className='time_sheet_text'>Office hours</Box>
                        <Box className='time_sheet_text'>WFH</Box>
                        <Box className='time_sheet_title _bold'>Total:</Box>
                    </Box>
                    <Box className="time_sheet_inner_div">
                        <span className='time_sheet_title'>HOURS</span>
                        <Box className='line_div' />
                        <Box className='time_sheet_text'>{`${this.state.timesheetData.total_office_duration?.hours} Hours ${this.state.timesheetData.total_office_duration?.mins} Mins`}</Box>
                        <Box className='time_sheet_text'>{`${this.state.timesheetData.total_wfh_duration?.hours} Hours ${this.state.timesheetData.total_wfh_duration?.mins} Mins`}</Box>
                        <Box className='time_sheet_title _bold _black'>{`${this.state.timesheetData.total_duration?.hours} Hours ${this.state.timesheetData.total_duration?.mins} Mins`}</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    feedbackModal = () => {
        return (
            <Modal
                open={this.state.feedbackModalOpen}
                onClose={this.handleFeedbackModalClose}
            >
                <Box className="feedback_modal_div">
                    <form
                        data-testid="feedbackSubmitButtonID"
                        onSubmit={this.handleClientFeedback}
                    >
                        <Box className="modal_title_div">
                            Feedback/Complaint
                            <span
                                data-testid="feedbackCloseButtonID"
                                onClick={this.handleFeedbackModalClose}
                            >
                                <CloseRounded />
                            </span>
                        </Box>
                        <Box className="input_div">
                            <label className='modal_inner_title'>First Name</label>
                            <OutlinedInput
                                fullWidth
                                error={this.state.nameError}
                                data-testid="nameTextInputID"
                                placeholder="Full name"
                                value={this.state.fristName}
                                onChange={this.onChangeFristName}
                            />
                            {this.errorView(this.state.nameError, "Please enter your name.", webStyle)}
                        </Box>
                        <Box className="input_div">
                            <label className='modal_inner_title'>Subject</label>
                            <OutlinedInput
                                fullWidth
                                error={this.state.subjectError}
                                data-testid="subjectTextInputID"
                                placeholder="Subject"
                                value={this.state.subject}
                                onChange={this.onChangeSubject}
                            />
                            {this.errorView(this.state.subjectError, "Please enter your subject.", webStyle)}

                        </Box>
                        <Box className="input_div">
                            <label className='modal_inner_title'>Feedback/Complaint</label>
                            <OutlinedInput
                                error={this.state.descripationError}
                                data-testid="feedbackTextInputID"
                                multiline
                                rows={6}
                                fullWidth
                                placeholder="Share your feedback/complaint"
                                value={this.state.reason}
                                onChange={this.onChangeReasonTextArea}
                            />
                            {this.errorView(this.state.descripationError, "Please enter your Feedback/Complaint.", webStyle)}

                        </Box>
                        <button
                            className="modal_button"
                            type='submit'
                        >
                            Submit Feedback
                        </button>
                    </form>
                </Box>
            </Modal>
        )
    }

    wfhModal = () => {
        return (
            <Modal
                open={this.state.wfhModalOpen}
                onClose={this.handleWfhModalClose}
            >
                <Box className="feedback_modal_div">
                    <form
                        data-testid="wfhSubmitButtonID"
                        onSubmit={this.handleApplyWfh}
                    >
                        <Box className="modal_title_div">
                            Apply for WFH
                            <span
                                data-testid="wfhCloseButtonID"
                                onClick={this.handleWfhModalClose}>
                                <CloseRounded />
                            </span>
                        </Box>
                        <Box className="input_div_wrapper">
                            <Box className="input_div">
                                <label className='modal_inner_title'>Start</label>
                                <TextField
                                    data-testid="startDateTextInputID"
                                    type="date"
                                    variant="outlined"
                                    value={this.state.startDate}
                                    inputRef={this.wfhStartcalender}
                                    inputProps={{ min: moment(new Date()).format("YYYY-MM-DD"), style: { color: 'black' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <img src={calender}
                                                    style={{ width: 15, height: 15 }}
                                                    alt='Calender'
                                                    onClick={this.handleWfhStartDate}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    onChange={this.onChangeStartDate} />
                            </Box>
                            <Box className="input_div">
                                <label className='modal_inner_title'>End</label>
                                <TextField
                                    data-testid="endDateTextInputID"
                                    type="date"
                                    variant="outlined"
                                    value={this.state.endDate}
                                    inputRef={this.wfhEndcalender}
                                    inputProps={{ min: this.state.startDate }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <img src={calender}
                                                    style={{ width: 15, height: 15 }}
                                                    alt='Calender'
                                                    onClick={this.handleWfhEndDate}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    onChange={this.onChangeEndDate} />
                            </Box>
                        </Box>
                        <Box className="input_div">
                            <label className='modal_inner_title'>Reason for work from home</label>
                            <textarea
                                data-testid="reasonTextInputID"
                                rows={12}
                                placeholder="Mention your reason for work from home (Optional)"
                                value={this.state.reason}
                                onChange={this.onChangeReasonTextArea}
                            />
                        </Box>
                        <button className="modal_button"
                            type='submit'
                        >Submit Request</button>
                    </form>
                </Box>
            </Modal>
        )
    }

    leaveModal = () => {
        return (
            <Modal
                open={this.state.applyLeaveModalOpen}
                onClose={this.handleLeaveModalClose}
            >
                <Box className="feedback_modal_div">
                    <form
                        data-testid="leaveSubmitButtonID"
                        onSubmit={this.handleApplyLeave}
                    >

                        <Box className="modal_title_div">
                            Apply for leave
                            <span
                                data-testid="leaveModalCloseButtonID"
                                onClick={this.handleLeaveModalClose}
                            >
                                <CloseRounded />
                            </span>
                        </Box>
                        <Box className="input_div_wrapper">
                            <Box className="input_div">
                                <label className='modal_inner_title'>Start</label>
                                <TextField
                                    data-testid="leaveStartDateID"
                                    type="date"
                                    variant="outlined"
                                    inputProps={{ min: moment(new Date()).format("YYYY-MM-DD") }}
                                    inputRef={this.leaveStartcalender}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <img src={calender}
                                                    style={{ width: 15, height: 15 }}
                                                    alt='Calender'
                                                    onClick={this.handleLeaveStartDate}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={this.state.startDate}
                                    required
                                    onChange={this.onChangeStartDate} />
                            </Box>
                            <Box className="input_div">
                                <label className='modal_inner_title'>End</label>
                                <TextField
                                    data-testid="leaveEndDateID"
                                    type="date"
                                    variant="outlined"
                                    inputProps={{ min: this.state.startDate }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <img src={calender}
                                                    style={{ width: 15, height: 15 }}
                                                    alt='Calender'
                                                    onClick={this.handleLeaveEndDate}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputRef={this.leaveEndcalender}
                                    value={this.state.endDate}
                                    required
                                    onChange={this.onChangeEndDate} />
                            </Box>
                        </Box>
                        <Box className="input_div">
                            <label className='modal_inner_title'>Reason for leave request</label>
                            <textarea
                                data-testid="leaveReasonID"
                                rows={12}
                                placeholder="Mention your reason for leave (Optional)"
                                value={this.state.reason}
                                onChange={this.onChangeReasonTextArea}
                            />
                        </Box>
                        <button className="modal_button"
                            type='submit'
                        >Submit Leave</button>

                    </form>
                </Box>
            </Modal>
        )
    }

    render() {
        return (
            <Box className="main_dashboard_div">

                {this.dashboardHeader()}

                {this.employeDetails()}

                {this.leavesDetails()}

                {this.state.dashboardApiData?.user_type == "manager" &&
                    <RequestManagementWeb leaveRequestsData={this.state.leaveRequestsData} page={this.state.page} handleGetLeaveRequests={(page: any) => this.handleGetLeaveRequests(page)} navigation={undefined} id={''} />}

                {this.timeSheet()}

                {this.feedbackModal()}

                {this.wfhModal()}

                {this.leaveModal()}

                <LeaveWFHLogs logsData={this.state.leaveLogsData} page={this.state.pageForLogs} openLogs={this.state.openLogs} closeLeaveWFHLogs={() => this.closeLeaveWFHLogs} handleGetLeaveLogs={(page: any) => this.handleGetLeaveLogs(page)} navigation={undefined} id={''} />
            </Box>
        )
    }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
    errorText: {
        color: 'red'
    },
};
export default withRouter(Dashboard);
export { Dashboard }
// Customizable Area End