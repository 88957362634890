import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { dropdown, notificationIcon } from "./assets";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Timesheetmanagement2Controller, {
  Props,
  configJSON,
} from "./Timesheetmanagement2Controller";

export default class Timesheetmanagement2 extends Timesheetmanagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <MainBox>
  
          <AttendanceMain>
          <NotificationBox>
        <AttendanceTypography>Attendance</AttendanceTypography>

        <div style={webStyle.iconNotification} onClick={this.handleNotifications}>
          <img
            style={webStyle.iconStyle as React.CSSProperties}
            src={notificationIcon}
          />
          <NotificationText>Notifications</NotificationText>
        </div>
      </NotificationBox>
  
            <Box style={webStyle.dataList as React.CSSProperties}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6} sm={3} md={3}>
                  <div style={webStyle.checkInOut as React.CSSProperties}>
                    <Typography style={webStyle.dayTxt as React.CSSProperties}>
                      {this.state.currentDayData?.todaysDay.substring(0, 3)}
                    </Typography>
                    <Typography style={webStyle.dateTxt as React.CSSProperties}>
                      {this.state.currentDayData?.todaysDate}
                    </Typography>
                    <Typography style={webStyle.typeTxt as React.CSSProperties}>
                      {configJSON.dateLabel}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div style={webStyle.checkInOut as React.CSSProperties}>
                    <Typography style={webStyle.dateTxt as React.CSSProperties}>
                      {this.state.currentDayData?.checkinTime ? this.formatToLocalTime(this.state.currentDayData?.checkinTime) : "-- --"}
                    </Typography>
                    <Typography style={webStyle.typeTxt as React.CSSProperties}>
                      {configJSON.checkInLabel}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <div style={webStyle.checkInOut as React.CSSProperties}>
                    <Typography style={webStyle.dateTxt as React.CSSProperties}>
                      {this.state.currentDayData?.checkOutTime ? this.formatToLocalTime(this.state.currentDayData?.checkOutTime) : "-- --"}
                    </Typography>
                    <Typography style={webStyle.typeTxt as React.CSSProperties}>
                      {configJSON.checkOutLabel}
                    </Typography>
                  </div>
                </Grid>
  
                <Grid item xs={6} sm={3} md={3}>
                  <div style={webStyle.checkInOut as React.CSSProperties}>
                    <Typography
                      style={webStyle.dateTxt as React.CSSProperties}
                    >{this.state.totalTime.hours}:{this.state.totalTime.mins} Hrs</Typography>
                    <Typography style={webStyle.typeTxt as React.CSSProperties}>
                      {configJSON.durationLabel}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Button
                style={webStyle.checkOutBtn as React.CSSProperties}
                onClick={this.checkInOut}
                data-testId="checkInout"
              >
                {this.state.currentDayData?.isCheckedIn ? "Check out" : "Check in"}
              </Button>
            </Box>
            <Box style={webStyle.allDataShow}>
              <div>
                <Typography style={webStyle.attnedText}>Attendance</Typography>
              </div>
              <div>
                <CustomSelectdiv>
                  <SelectTypography onClick={this.handleSelectClick}>
                    {this.state.selectedMonth}
                  </SelectTypography>
                  <img src={dropdown} alt=""></img>
                </CustomSelectdiv>
  
                <div>
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleSelectClose}
                    PaperProps={{
                      style: {
                        width: "180px",
                      },
                    }}
                  >
                    {
                      this.state.listOfMonth.map((el,k)=>{
                    return <MenuItem
                        key={el}
                      data-testId="thismonth"
                      onClick={this.handleOptionSelect.bind(this,el)}
                    >
                      {el}
                    </MenuItem>
                      })
                    }
                  </Menu>
                </div>
              </div>
            </Box>
  
            <Box style={webStyle.mainCheckOutBox as React.CSSProperties}>
              {this.state.attendanceData.map((item: any, index: any) => (
                <DataShowBox key={index}>
                  <Box style={webStyle.durationBox as React.CSSProperties}>
                    <div
                      style={webStyle.dataStyleTxtRight as React.CSSProperties}
                    >
                      <Typography>{configJSON.dateLabel} :</Typography>
                      <DuarationTypography>{configJSON.durationLabel} :</DuarationTypography>
                    </div>
                    <DataShowRight>
                      <Typography> {item.attributes.date_formatted}</Typography>
                      <DuarationTypography>
                      {item.attributes.total_working_hours.hours} hrs {item.attributes.total_working_hours.mins} mins
                        {/* {data.attributes.total_working_hours.hours}hrs
                        <span style={webStyle.spanStyle}>
                          {data.attributes.total_working_hours.mins}
                        </span>mins */}
                      </DuarationTypography>
                    </DataShowRight>
                  </Box>
                  <Box style={webStyle.checkOutBox as React.CSSProperties}>
                    <div style={webStyle.dataStyleTxt as React.CSSProperties}>
                      <Typography>{configJSON.checkInLabel} : </Typography>
                      <DuarationTypography>{configJSON.checkOutLabel} : </DuarationTypography>
                    </div>
                    <DataShowRight>
                      <Typography>
                      {this.formatToLocalTime(item.attributes.check_in)}
                      </Typography>
                      <DuarationTypography>
                      {this.formatToLocalTime(item.attributes.check_out)}
                      </DuarationTypography>
                    </DataShowRight>
                  </Box>
                </DataShowBox>
              ))}
            </Box>
          </AttendanceMain>
        </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  spanStyle:{
marginLeft:'5px'
  },
  checkInOut: {
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "4px",
    flex: "1 0 0",
    alignSelf: "stretch",
    height: "94px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  },
  checkOutBtn: {
    marginTop: "16px",
    display: "flex",
    height: "56px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: " #0B357D",
    width: "100%",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "none",
  },
  showData: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderRadius: " 6px",
    border: "1px solid #E2E8F0",
    padding: "12px 20px 12px 20px",
    boxShadow: "0px 1px 4px 0px #0000000D",
    marginTop: "20px",
    background: "#FFFFFF",
  },
  allDataShow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  attnedText: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px",
  },
  dataStyleTxt: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
  dataStyleTxtRight: {
    display: "flex",
    flexDirection: "column",
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
  dataStyleShowTxt: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  durationdata: {
    marginTop: "8px",
  },
  dayTxt: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "left",
  },
  dateTxt: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "left",
  },
  typeTxt: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    textAlign: "center",
    color: "#0B357D",
  },
  dataList: {
    marginTop: "20px",
  },
  checkOutBox: {
    display: "flex",
  },
  durationBox: {
    display: "flex",
  },
  mainCheckOutBox: {
    maxHeight: "90%",
  },
  headingBox2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "25px",
  },
  iconNotification: {
    display: "flex",
    alignItmes: "center",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    cursor: 'pointer',
  },
  attendanceHeading: {
    fontFamily: "Inter",
    fontSize: " 24px",
    fontWeight: 600,
    lineHeight: " 32px",
    textAlign: "left",
    color: "#334155",
  },
  notificationText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    textAlign: "center",
    color: "#0B357D",
    fontStyle: "normal",
    "@media (max-width: 700px)": {
      display: "none",
    },
  },
  iconStyle: {
    width: "24px",
    height: "24px",
  },
};

const NotificationBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "25px",
  "@media (max-width: 700px)": {
    paddingTop: "5px",
    justifyContent: "space-around",
    // maxWidth: '80vw',
    marginLeft: "auto",
  },
});

const NotificationText = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "18px",
  textAlign: "center",
  color: "#0B357D",
  fontStyle: "normal",
  "@media (max-width: 700px)": {
    display: "none",
  },
});

const AttendanceTypography = styled(Typography)({
  fontFamily: "Inter",
  fontSize: " 24px",
  fontWeight: 600,
  lineHeight: " 32px",
  textAlign: "left",
  color: "#334155",
  "@media (max-width: 900px)": {
    paddingLeft: "55px",
  },
});

const MainBox = styled("div")({
  backgroundColor: "#F5F9FF",
  padding: "0px 0px 20px 24px",
  display: "flex",
  height: "inherit",

  "@media (max-width: 900px)": {
    width: "100%",
    padding: "20px",
    paddingTop:0
  },
  "@media (max-width: 700px)": {
    padding: "10px",
    paddingTop:0
  },
});

const SideBarMain = styled("div")({
  width: "10%",
  "@media (max-width: 900px)": {
    width: "0px",
  },
});
const AttendanceMain = styled("div")({
  width: "100%",
  "@media (max-width: 900px)": {
    width: "100%",
  },
});
const DuarationTypography = styled(Typography)({
  marginTop: "8px",
  "@media (max-width: 600px)": {
    marginTop: "5px",
  },
});
const DataShowRight = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "20px",
  color: "#000",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  "@media (max-width: 700px)": {
    marginLeft: "0px",
    fontSize: "12px",
  },
});
const DataShowBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  borderRadius: " 6px",
  border: "1px solid #E2E8F0",
  padding: "12px 20px 12px 20px",
  boxShadow: "0px 1px 4px 0px #0000000D",
  marginTop: "20px",
  background: "#FFFFFF",
  "@media (max-width: 700px)": {
    padding: "12px 5px 12px 5px",
  },
});
const SelectTypography = styled(Typography)({
  color: "#64748B",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 501,
  lineHeight: "22px",
  width: "142px",
  cursor: "pointer",
  padding: "4px 0px 4px 0px",
  marginTop: "4px",
});
const CustomSelectdiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  background: "white",
  cursor: "pointer",
  padding: "0px 8px 0px 8px",
  border: "1px solid #E2E8F0",
  borderRadius: "4px",
  width: "182px",
  height: "40px",
});
const HamburgerLogo = styled("img")({
  "@media (min-width: 900px)": {
    display: "none",
  },
});

// Customizable Area End
