import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
export interface  CurrentDayData {
  "checkinTime": string,
  "checkOutTime": string,
  "todaysDate": string,
  "todaysDay": string,
  "isCheckedIn": boolean
}

export interface ItemType {
 item:{ "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "date": string,
      "check_in": string,
      "check_out": string,
      "account_id": number,
      "date_formatted": string,
      "total_working_hours": {
          "hours": number,
          "mins": number
      }
  }}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token:string | null;
  totalTime: {
    hours: string,
    mins: string
  };
  selectedMonth: string;
  listOfMonth: any[];
  timesheetData: any[];
  attendanceData: any;
  isDropDownOpen: boolean;
  isCheckedIn: boolean;
  currentDayData : CurrentDayData | null;
  anchorEl: null | HTMLElement;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Timesheetmanagement2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getAttendanceDataApiCallID: string = "";
  checkinOutApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token:"",
      totalTime: {
        hours: "",
        mins: ""
      },
      attendanceData: [],
      listOfMonth: [
        "Jan", "Feb", "March", "April", "May", "June",
        "July", "Aug"
      ],
      selectedMonth: "This Month",
      timesheetData: [],
      isDropDownOpen: false,
      isCheckedIn: false,
      currentDayData:{
        "checkinTime": "",
        "checkOutTime": "",
        "todaysDate": "",
        "todaysDay": "",
        "isCheckedIn": false
      },
      anchorEl: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

const apiRequestCallId = message.getData(
  getName(MessageEnum.RestAPIResponceDataMessage)
);

let responseJson = message.getData(
  getName(MessageEnum.RestAPIResponceSuccessMessage)
);

let errorReponse = message.getData(
  getName(MessageEnum.RestAPIResponceErrorMessage)
);

if (apiRequestCallId != null) {
  if (responseJson && !responseJson.errors) {
    if (apiRequestCallId === this.getAttendanceDataApiCallID) {
      const attendanceData = responseJson.data.data.reverse();
      
      this.setState({currentDayData:responseJson.currentDayData,attendanceData:attendanceData})
      responseJson.currentDayData.checkinTime  && this.calculateTotalTime();
    }
    else if (apiRequestCallId === this.checkinOutApiCallID) {
      this.setState({currentDayData:this.state.currentDayData,selectedMonth:"This Month"});
      this.getAttendanceData()
    }
  } else {
    //Check Error Response
    this.parseApiErrorResponse(responseJson);
  }
  this.parseApiCatchErrorResponse(errorReponse);}
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    let token = localStorage.getItem("loginToken");
    this.setState({ token }, () => this.getAttendanceData());
    const currentDate = new Date();

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const currentMonthIndex = currentDate.getMonth();

const monthList = [];

for (let i = 0; i < currentMonthIndex; i++) {
  monthList.push(monthNames[i]);
}
let monthsList = [...monthList];
monthsList.reverse();

this.setState({ listOfMonth: ["This Month", ...monthsList] })
  }

  openDropDown = () => {
    this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
  }

  selectMonthItem = (item: string, index: number) => {
    this.setState({ selectedMonth: item, isDropDownOpen: false });
    this.getAttendanceData(item=="This Month" ? undefined : item)
  }
  convertTo12HourFormat(timeString: string): string {
    if(!timeString.includes(":")){
      return timeString
    }
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours === 12 ? 12 : hours % 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

formatToLocalTime(dateTimeString: string | null | Date): string {  
  if(dateTimeString==null){
    return "-- --"
  }
  const dateTime = new Date(dateTimeString);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone:timeZone
  };
  return dateTime.toLocaleTimeString("en-US", options);
}
  calculateTotalTime = () => {
    const checkInTimeString: string = this.state.currentDayData?.checkinTime ? this.state.currentDayData?.checkinTime : "";

const checkInTime: Date = new Date(checkInTimeString);
const currentTime = this.state.currentDayData?.checkOutTime ? this.state.currentDayData?.checkOutTime : new Date();
const checkOutTime :Date= new Date(currentTime)

const timeDifference: number = checkOutTime.getTime() - checkInTime.getTime();

const hours: number = Math.floor(timeDifference / (1000 * 60 * 60));
const mins: number = Math.round((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
const formattedHours: string = hours.toString().padStart(2, '0');
const formattedMinutes: string = mins.toString().padStart(2, '0');

this.setState({
  totalTime: {
    hours: formattedHours, mins: formattedMinutes
  }
})
this.state.currentDayData?.isCheckedIn && setInterval(this.calculateTotalTime, 60000);
  }

  getAttendanceData = (month?: string) => {

const headers = {
  "Content-Type": configJSON.ContentType,
  token: this.state.token
};

const getAttendanceDataMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.getAttendanceDataApiCallID = getAttendanceDataMessage.messageId;

let endPoint;
if(month){
  endPoint = configJSON.getAttendanceAPiEndPoint + `?month=${month}`;
}else{
  endPoint = configJSON.getAttendanceAPiEndPoint;
}
getAttendanceDataMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
);

getAttendanceDataMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(headers)
);
getAttendanceDataMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.GET
);
runEngine.sendMessage(getAttendanceDataMessage.id, getAttendanceDataMessage);
  }

  checkInOut = () => {

const currentTimeStamp = new Date();
console.log("checkinout >>>>", moment(), new Date());


const header = {
  "Content-Type": configJSON.ContentType,
  token: this.state.token
};

let data;
if(this.state.currentDayData?.isCheckedIn){
  data= {
    "attributes": {
      check_out: currentTimeStamp
    }
  }
}else{
  data= {
    "attributes": {
      check_in: currentTimeStamp
    }
  }     
}
const body = {
  "data":data
}

const checkInOutDataMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.checkinOutApiCallID = checkInOutDataMessage.messageId;

checkInOutDataMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getAttendanceAPiEndPoint
);
checkInOutDataMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
);

checkInOutDataMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
checkInOutDataMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.POST
);
runEngine.sendMessage(checkInOutDataMessage.id, checkInOutDataMessage);
  }


  handleSelectClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSelectClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOptionSelect = (item: any) => {
    this.setState({ selectedMonth: item, anchorEl: null });
    this.getAttendanceData(item=="This Month" ? undefined : item)
  };

  handleNotifications=()=>{
    this.props.history.push('/notifications')
}
  // Customizable Area End
}
