Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "timesheetmanagement2";
exports.labelBodyText = "timesheetmanagement2 Body";
exports.getAttendanceAPiEndPoint = "/bx_block_timesheetmanagement2/attendances";
exports.btnExampleTitle = "CLICK ME";

exports.ContentType = "application/json";
exports.GET = "GET";
exports.getAttendanceAPiEndPoint = "/bx_block_timesheetmanagement2/attendances";
exports.checkinOutAPiEndPoint = "EXAMPLE_URL";
exports.POST = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "timesheetmanagement2";
exports.labelBodyText = "timesheetmanagement2 Body";
exports.attendanceLabel="Attendance"

exports.btnExampleTitle = "CLICK ME";
exports.dateLabel="Date"
exports.durationLabel="Duration"
exports.checkInLabel="Check in"
exports.checkOutLabel="Check out"

// Customizable Area End