import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  Grid,
  Modal,
  FormHelperText,
} from "@material-ui/core";
import { CloseRounded } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { BlockComponent } from "framework/src/BlockComponent";
import moment from "moment";
import { leftIconGrey, rightIconGrey } from "./assets";

// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableHead=()=>{
    return(
      <TableHead style={tableStyles.tableHead}>
      <TableRow style={tableStyles.tableRow}>
        <TableCell style={webStyle.label}>EMPLOYEE NAME</TableCell>
        <TableCell style={webStyle.label}>FROM DATE</TableCell>
        <TableCell style={webStyle.label}>TO DATE</TableCell>
        <TableCell style={webStyle.label}>REASON FOR LEAVE/WFH</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    )
}

renderTableRow = () => {
  if(this.props.leaveRequestsData?.paginated_data?.length > 0){
    return this.props.leaveRequestsData.paginated_data
    .map((row:any) => (
      <TableRow key={row.id} style={tableStyles.tableRow}>
          {this.leaveModal()}
          {/* @ts-ignore */}
          <TableCell style={tableStyles.tableBodyText}>
          {/* @ts-ignore */}
            <Box style={tableStyles.aboutView}>
              <img
                src={require("../assets/profileLogo.jpeg")}
                alt="modal"
                // @ts-ignore
                style={tableStyles.avatarImage}
                data-testid="openProfileButtonId"
              />
              <Box>
                <Typography style={tableStyles.name}>
                  {row.first_name + " " + row.last_name}
                </Typography>
                <Typography style={tableStyles.email}>{row.email}</Typography>
              </Box>
            </Box>
          </TableCell>
          {/* @ts-ignore */}
          <TableCell style={tableStyles.tableBodyText}>
            {moment(row.start_date).format("Do MMMM, YYYY")}
          </TableCell>
          {/* @ts-ignore */}
          <TableCell style={tableStyles.tableBodyText}>
            {moment(row.end_date).format("Do MMMM, YYYY")}
          </TableCell>
          <TableCell style={tableStyles.requestCell}>
            <Box>
          {/* @ts-ignore */}
              <Typography style={tableStyles.tableBodyText}>
                Request for {row.action_type === "wfh" ? "WFH" : "Leave"}
              </Typography>
              <Tooltip title={row.reason}>
                <Typography className="ellipsis_text" style={tableStyles.dotText}>{row.reason}</Typography>
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell>
          {/* @ts-ignore */}
            <Box style={tableStyles.buttonsView}>
              <Typography
                data-test-id="acceptBtn"
                // @ts-ignore
                style={tableStyles.textView}
                onClick={() =>
                  this.acceptRequest(row.wfh_leave_year_id, row.id)
                }
              >
                Accept
              </Typography>
              <Typography
              data-test-id="rejectBtnID"
              // @ts-ignore
                style={tableStyles.textViewReject}
                onClick={() => {
                  this.handleRejectLeaveModalOpen(
                    row.wfh_leave_year_id,
                    row.id
                  );
                }}
              >
                Reject
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ));
  }
};

leaveModal = () => {
  
  return (
    <Modal
    open={this.state.rejectLeaveModalOpen}
    onClose={this.handleRejectLeaveModalClose}
  >
    <Box className="feedback_modal_div">
      <form
        data-testid="leaveSubmitButtonID"
        onSubmit={this.rejectRequest}
      >
        <Box className="modal_title_div">
          Reason for Rejection
          <span
            data-testid="leaveModalCloseButtonID"
            onClick={this.handleRejectLeaveModalClose}
          >
            <CloseRounded />
          </span>
        </Box>
        <Box className="input_div">
          <label className="modal_inner_title">Reason for Rejection</label>
          <textarea
            data-testid="leaveReasonID"
            rows={12}
            placeholder="Mention your reason for rejection"
            value={this.state.feedback}
            onChange={this.onChangeFeedbackTextArea}
            style={this.state.isfeedbackError ? webStyle.errorText : {}}
          />
          {this.state.isfeedbackError && <FormHelperText style={webStyle.errorTextColor}>Please enter reason for rejection.</FormHelperText>}
        </Box>
                  <button className="modal_button"
                      type='submit'
                  >{configJSON.submit}</button>

              </form>
          </Box>
      </Modal>
  )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const totalCount = parseInt(this.props.leaveRequestsData?.total_count);
    return (
      <Box className="dashboard_inner_div">
      <Grid container style={{ marginBottom: "15px" }}>
        <Typography style={tableStyles.tableHeading}>
          Approve Leaves/WFH of Employee
        </Typography>
      </Grid>
      {/* @ts-ignore */}
      <Paper style={this.props.leaveRequestsData?.total_count == 0 ? tableStyles.tablePaperNoData : tableStyles.tablePaper}>
        {this.props.leaveRequestsData?.total_count === 0 ? (
          <Typography style={webStyle.labelNoRequest}>
            You have no pending requests!
          </Typography>
        ) : (
          <Table style={{ minWidth: "300px" }}>
            {this.renderTableHead()}
            <TableBody>{this.renderTableRow()}</TableBody>
          </Table>
        )}
      </Paper>

      {totalCount != 0 && (
        <Grid container style={{ marginTop: "20px" }}>
          <CustomPagination
            currentPage={this.props.page}
            totalPages={
              totalCount < 4
                ? 1
                : Math.ceil(totalCount / 4)
            }
            onChange={(value: any) => {
              this.props.handleGetLeaveRequests(value);
            }}
          />
        </Grid>
      )}
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
interface IProps {
  currentPage: any;
  totalPages: any;
  onChange: any;
}

interface IS {
}

interface ISS {
}

class CustomPagination extends BlockComponent<IProps, IS, ISS> {

  constructor(props:any){
    super(props)
    this.state = {
      pageNumbers : this.generatePageNumbers()
    }
    // ({ this.props.currentPage, this.props.currentPage, onChange })
  }

  // Function to generate an array of page numbers
  generatePageNumbers=()=> {
    const pageNumbers = [];
    const { totalPages } = this.props;

    for (let page = 1; page <= totalPages; page++) {
      pageNumbers.push(page);
    }

    return pageNumbers;
  }

  handlePageChange = (event:any, value:any) => {
    this.props.onChange(value);
    this.setState({ pageNumbers: this.generatePageNumbers() });
  };

  previous=()=>{
    if(this.props.currentPage > 1){
      this.props.onChange(this.props.currentPage - 1)
    }
  }
  
  next=() => {
    if(this.props.currentPage < this.props.totalPages){
      this.props.onChange(this.props.currentPage + 1)
    }
    }

  render(){
    const count= parseInt(this.props.totalPages) || 1;
    
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:"100%",
      borderTop:"2px",
      borderStyle:'solid',
      borderColor:"#E5E7EB",
      borderRight:0,
      borderLeft:0,
      borderBottom:0,
      paddingTop:10
      }}>

        <Box>
          <Typography onClick={this.previous} style={webStylePagination.leftArrowText} data-test-id="previous">
            <img src={leftIconGrey} style={webStylePagination.leftArrowText}/> Previous</Typography>
      </Box>
      <Pagination
      count={count}
      page={this.props.currentPage}
      onChange={(event, value)=>this.handlePageChange(event, value)}
      renderItem={(item) => {
        // @ts-ignore
        if (item === -1) {
          return  <PaginationItem
          component="button"
          onClick={() => this.handlePageChange(null, item)} // Handle custom "..." element click
          disabled
          data-test-id="item"
        >
          ...
        </PaginationItem>;
        } else {
          return <>
          {/* @ts-ignore */}
          <div style={item?.page == this.props.currentPage ? webStylePagination.selectedNumberBorder : {}}></div>
          <PaginationItem {...item} style={item?.page == this.props.currentPage? webStylePagination.selectedNumberText : webStylePagination.numberText}
          data-test-id="item"
          />
          </>
        }
      }}
      hidePrevButton
      hideNextButton
      siblingCount={0}
      // boundaryCount={1}
      shape="rounded"
      />
       <Box>
          <Typography style={webStylePagination.arrowText} onClick={this.next}
            data-test-id="next">
            Next <img src={rightIconGrey} style={{paddingLeft:"10px"}}/></Typography>
      </Box>
      </div>
      );
    }
  }

export {CustomPagination};

const webStylePagination = {
  arrowText: {
    display:'flex', alignItems:'center',
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    cursor:"pointer"
  },
  leftArrowText:{
    display:'flex', alignItems:'center',
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingRight:"10px",
    cursor:"pointer"
  },
  numberText:{
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingRight:"10px",
    backgroundColor:"transparent",
  },
  selectedNumberBorder:{
    borderTopWidth:"1px",
    borderStyle:'solid',
    borderTopColor:"#0B357D",
    borderBottomWidth:0,
    position:"relative",
    top:-10,
  },
  selectedNumberText:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingRight:"10px",
    backgroundColor:"transparent",
  },
};

const tableStyles = {
  tableHeading: {
    fontFamily: "Inter",
    fontSize: "15px",
    color: "#1E293B",
    fontWeight: 600,
    lineHeight: "26px",
    letterSpacing: "0em",
  },
  tablePaperNoData:{
    width: '100%',
    overflowX: 'auto', 
    minHeight:"266px",
    justifyContent:'center',
    alignItems:"center",
    display:"flex"
  },
  tablePaper:{
    width: '100%',
    overflowX: 'auto', 
    minHeight:"266px",
    border: "1px solid #F9FAFB"
  },
  tableHead: {
    backgroundColor: "#f9fafb",
    borderRadius: "8px",
    "& .MuiTableCell-head": {
      padding: "10px 16px",
      fontSize: "16px",
    },
  },
  tableRow: {
    // Add your row styles here
  },
  tableBodyText: {
    textAlign: "left",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  aboutView: {
    flexDirection: "row",
    display: "flex",
  },
  avatarImage: {
    marginRight: "15px",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  name: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#111827",
  },
  email: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#6B7280",
  },
  requestCell: {
    maxWidth: "0px",
  },
  dotText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    
    color: "#64748B",
    maxWidth: "100%",
  },
  buttonsView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
  textView: {
    textAlign: "left",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    cursor: "pointer",
    color: "#0B357D",
  },
  textViewReject: {
    textAlign: "left",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    cursor: "pointer",
    color: "#F87171",
    marginLeft: "15px",
  },
};

const modalStyles = {
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "15px",
    top: "20px",
    color: "#94A3B8",
  },
};

const webStyle = {
  label: {
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    marginTop: "10px",
  },
  errorText:{
    border: '1px solid red',
  },
  errorTextColor:{
    color:'red'
  },
  labelNoRequest: {
    color: "#6B7280",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 20,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
};

// Customizable Area End
