// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    history: any;
    match: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    Show_profile_popup: boolean
    menuOpen: null | HTMLElement;
    mobileOpen: boolean;
    activeLink: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LayoutControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    wrapperRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            Show_profile_popup: false,
            menuOpen: null,
            mobileOpen: false,
            activeLink: window.location.pathname,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleIconClick = (url: any) => {
        this.props.history.push(url)
    };

    menuClcik = (url: string) => {
        if (url == "/signin") {
            localStorage.clear()
            this.props.history.push(url)
        } else {
            localStorage.removeItem("tabID");
            this.props.history.push(url)
        }
    };

    handleDrawerToggle = () => {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    };

    handleMenuClick = (event: React.MouseEvent<HTMLButtonElement | any>) => {
        this.setState({ menuOpen: event?.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ menuOpen: null });
    };
    // Customizable Area End
}
