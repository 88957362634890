Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.leaveRequestsEndPoint = "bx_block_dashboard/wfh_leaves";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.postMethodType = "POST";
exports.dashboardGet = "/bx_block_dashboard/dashboards";
exports.applyLeave ="/bx_block_dashboard/wfh_leaves";
exports.clientFeedback ="/bx_block_dashboard/complaints";
exports.timesheetEndpoint = "bx_block_dashboard/dashboards/total_calculated_time"
// Customizable Area End