import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';

import HomeScreen from "../../components/src/HomeScreen";

import Loader from "../../components/src/Loader.web";
import PrivateRoute from "./PrivateRoute.web";

//Import component here
import Layout from '../../blocks/adminconsole2/src/Layout.web'
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";

import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Attendance from "../../blocks/timesheetmanagement2/src/Timesheetmanagement2.web"
import Notifications from "../../blocks/notifications/src/Notifications.web"

const ForgotPassword = lazy(() => import("../../blocks/forgot-password/src/ForgotPassword.web"));
const SetNewPassword = lazy(() => import("../../blocks/forgot-password/src/NewPassword.web"));
const EmailAccountLogin = lazy(() => import("../../blocks/email-account-login/src/EmailAccountLogin.web"));
const EmailAccountRegistration = lazy(() => import("../../blocks/email-account-registration/src/EmailAccountRegistration.web"));
const ViewProfile = lazy(() => import('../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'));
const ChangePassword = lazy(() => import('../../blocks/customisableuserprofiles/src/ChangePassword.web'));
const PrivacyPolicy = lazy(() => import('../../blocks/termsconditions/src/PrivacyPolicy.web'));
const PageNotFound = lazy(() => import('../../blocks/termsconditions/src/404.web'));
const Termsconditions = lazy(() => import('../../blocks/termsconditions/src/TermsConditions.web'));
const AddSlots = lazy(() => import("../../blocks/scheduling/src/AddSlots.web"));
const CommunityGroup = lazy(() => import("../../blocks/usergroupcreation/src/Usergroupcreation.web"));


if (!HomeScreen.instance) {
    const defaultProps = {
        navigation: null,
        id: "HomeScreen"
    };
    const homeScreen = new HomeScreen(defaultProps);
}

const WebRoutes = () => {
    return (
        <Router>
            <Suspense fallback={<Loader loading />}>
                <Switch>
                    <Route path="/forgotpassword" exact render={props => <ForgotPassword navigation={undefined} id={''} {...props} />} />
                    <Route path="/newpassword" exact render={props => <SetNewPassword navigation={undefined} id={''} {...props} />} />
                    <Route path="/signin" exact render={props => <EmailAccountLogin navigation={undefined} id={''} {...props} />} />
                    <Route path="/signup" exact render={props => <EmailAccountRegistration navigation={undefined} id={''} {...props} />} />
                    <Route path="/contactus" exact render={props => <LandingPage navigation={undefined} id={''} {...props} />} />
                    <Route path="/termsconditions" exact render={props => <Termsconditions navigation={undefined} id={''} {...props} />} />
                    <Route path="/privacypolicy" exact render={props => <PrivacyPolicy navigation={undefined} id={''} {...props} />} />
                    <Route path="/changepassword" exact render={props => <ChangePassword navigation={undefined} id={''} {...props} />} />

                    <PrivateRoute>
                        <Layout>
                            <Route path="/" exact render={props => <Dashboard navigation={undefined} id={''} {...props} />} />
                            <Route path="/attendance" exact render={props => <Attendance navigation={undefined} id={''} {...props} />} />
                            <Route path="/viewprofile" exact render={props => <ViewProfile navigation={undefined} id={''} {...props} />} />
                            <Route path="/notifications" exact render={props => <Notifications navigation={undefined} id={''} {...props} />} />
                            <Route path="/addslots" exact render={props => <AddSlots id={''} {...props}/>} />
                            <Route path="/communitygroup" exact render={props => <CommunityGroup navigation={undefined} id={''} {...props}/>} />
                        </Layout>
                    </PrivateRoute>

                </Switch>
            </Suspense>
        </Router>
    )
}

export default withRouter(WebRoutes);